import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64544efb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-section" }
const _hoisted_2 = {
  key: 1,
  class: "tags-title-container"
}
const _hoisted_3 = { class: "tags-container" }
const _hoisted_4 = { class: "form-section bordered" }
const _hoisted_5 = { class: "b-form-input" }
const _hoisted_6 = { class: "centered" }
const _hoisted_7 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_8 = { class: "centered" }
const _hoisted_9 = { class: "form-section bordered" }
const _hoisted_10 = { class: "b-form-input" }
const _hoisted_11 = { class: "centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_TrackedItemTagModal = _resolveComponent("TrackedItemTagModal")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_safety_inspection_questionnaire, {
      modelValue: _ctx.state.showEquipmentInspection,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showEquipmentInspection) = $event)),
      "inspection-type": _ctx.SafetyInspectionType.EquipmentInspection,
      "inspection-title": _ctx.getTitleCaseTranslation('core.common.equipmentSafetyInspection'),
      onCanceled: _ctx.cancelSafetyInspection,
      onRejected: _ctx.cancelSafetyInspection
    }, null, 8, ["modelValue", "inspection-type", "inspection-title", "onCanceled", "onRejected"]),
    _createVNode(_component_screen, {
      title: _ctx.getTitleCaseTranslation('core.domain.warehouseMovement'),
      heading: _ctx.getTitleCaseTranslation('core.domain.warehouseMovement'),
      full: "",
      padded: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_smart_trak_footer, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_button, {
              disabled: !_ctx.savable,
              class: "submit-button",
              variant: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitClick()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, {
              cols: "4",
              "cols-offset": "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.item')), 1),
                  (!_ctx.state.usingTags)
                    ? (_openBlock(), _createBlock(_component_item_picker, {
                        key: _ctx.state.componentKey,
                        "disabled-qty-picker": !_ctx.state.ready,
                        "item-type": _ctx.ItemType.WAREHOUSE_MOVEMENT,
                        "search-only": "",
                        placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
                        "focus-on-load": false,
                        onOnSelect: _ctx.addItem,
                        onOnClear: _ctx.resetState
                      }, null, 8, ["disabled-qty-picker", "item-type", "placeholder", "onOnSelect", "onOnClear"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_b_form_input, {
                          class: "tags-selected-item-input",
                          "model-value": _ctx.selectedItemName,
                          disabled: ""
                        }, null, 8, ["model-value"]),
                        _createVNode(_component_b_button, {
                          class: "button-clear",
                          onClick: _ctx.resetState
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.clear')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "1" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.state.usingTags)
                    ? (_openBlock(), _createBlock(_component_TrackedItemTagModal, {
                        key: 0,
                        tags: _ctx.state.trackedItems,
                        title: _ctx.state.item?.name,
                        disabled: !_ctx.state.usingTags,
                        onRemoveTag: _ctx.removeTag
                      }, null, 8, ["tags", "title", "disabled", "onRemoveTag"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_b_row),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.from')), 1),
                    _createVNode(_component_dropdown_autocomplete_single_select, {
                      loading: _ctx.state.loading,
                      "model-value": _ctx.state.fromInventoryCategoryOrFloorLocation?.text,
                      data: _ctx.state.movableInventoryCategories,
                      autofocus: _ctx.fromInventoryCategoryAutofocus,
                      "search-by": "text",
                      "display-name": "text",
                      "select-on-enter": true,
                      onOnSelect: _ctx.selectFromInventoryCategory
                    }, null, 8, ["loading", "model-value", "data", "autofocus", "onOnSelect"])
                  ]),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.onHandInventory')) + ": " + _toDisplayString(_ctx.fromInventoryOnHandForCategorySelected), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_number_input, {
                    modelValue: _ctx.state.itemQty,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.itemQty) = $event)),
                    autofocus: _ctx.state.item && _ctx.fromInventoryOnHandForCategorySelected && !_ctx.savable && !_ctx.state.usingTags,
                    min: _ctx.state.trackedItems.length,
                    label: _ctx.getTitleCaseTranslation('core.domain.quantity')
                  }, null, 8, ["modelValue", "autofocus", "min", "label"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_thumbnail, {
                      "image-url-thumb": _ctx.state.item?.imageUrlThumb,
                      "image-url-full": _ctx.state.item?.imageUrlFull,
                      size: "lg"
                    }, null, 8, ["image-url-thumb", "image-url-full"])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.to')), 1),
                    _createVNode(_component_dropdown_autocomplete_single_select, {
                      loading: _ctx.state.loading,
                      "model-value": _ctx.state.toInventoryCategoryOrFloorLocation?.text,
                      data: _ctx.state.movableInventoryCategories,
                      "search-by": "text",
                      "display-name": "text",
                      "select-on-enter": true,
                      autofocus: _ctx.toInventoryCategoryAutofocus,
                      onOnSelect: _ctx.selectToInventoryCategory
                    }, null, 8, ["loading", "model-value", "data", "autofocus", "onOnSelect"])
                  ]),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.onHandInventory')) + ": " + _toDisplayString(_ctx.toInventoryOnHandForCategorySelected), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title", "heading"])
  ], 64))
}